<template>
  <div class="h-backgroud-color_body">
    <HYList ref="HYList" v-bind="listConfig">
      <template #columnHead="{ row }">
        <div class="row">
          <div class="row-head h-flex h-row-between">
            <span>申请日期：{{ row.applyTime && dayjs(row.applyTime).format("YYYY-MM-DD") }}</span>
            <span class="h-primary-color">{{ row.claimStatus | dictFormat("CS_CLAIM_STATUS") }}</span>
          </div>
          <div class="row-content">
            <div class="h-font-md h-flex">
              <span class="row-content_title">履约申请号：</span>
              <span>{{ row.claimNo }}</span>
            </div>
            <div class="h-font-md h-flex">
              <span class="row-content_title">客户名称：</span>
              <span>{{ row.customerName }}</span>
            </div>
            <div class="h-font-md h-flex">
              <span class="row-content_title">车架号：</span>
              <span>{{ row.frameNo }}</span>
            </div>
            <div class="h-font-md h-flex">
              <span class="row-content_title">服务类型：</span>
              <span>{{ row.productName }}</span>
            </div>
          </div>
          <div class="row-foot h-flex h-row-right">
            <van-button v-if="row.claimStatus === '3'" type="danger" size="small" @click="toCancel(row)">撤销</van-button>
            <van-button v-if="row.claimStatus === '3' || row.claimStatus === '8'" type="primary" size="small" @click="toEdit(row)">上传资料</van-button>
            <van-button v-if="row.claimStatus != '5'" type="primary" size="small" @click="toDetail(row)">查看</van-button>
          </div>
        </div>
      </template>
      <template #empty>
        <div class="none-tip">
          <van-image :src="require('@/assets/images/none_data.jpg')" />
          <div>无查询信息！</div>
        </div>
      </template>
    </HYList>
  </div>
</template>
<script>
import HYList from "@/components/List/index";
import dayjs from "dayjs";
import { request } from "@/api/service";
export default {
  name: "ClaimList",
  components: { HYList },
  props: {
    name: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      listConfig: {
        styleObj: {
          minHeight: null
        },
        api: {
          url: "/afis-carservice/web/claim/commonList"
        },
        form: {
          // terminal:"3",//移动端
          name: this.name,
          claimStatus: this.type
        },
        column: []
      }
    };
  },
  watch: {
    name: function(val) {
      this.listConfig.form.name = this.name;
      this.onSearch();
    }
  },
  methods: {
    dayjs,
    onSearch() {
      this.$refs.HYList.onRefresh();
    },
    toDetail(row) {
      this.$router.push({
        path: "/product/claim/detail",
        query: {
          id: row.id,
          productCode: row.productCode
        }
      });
    },
    toEdit(row) {
      this.$router.push({
        path: "/product/claim/apply/base",
        query: {
          id: row.id,
          productCode: row.productCode
        }
      });
    },
    toCancel(row) {
      const { id, claimNo } = row;
      this.$dialog.confirm({
        title: "撤销确认",
        message: `确认要撤销${claimNo}?`,
        beforeClose: (action, done) => {
          if (action !== "confirm") {
            done();
            return false;
          }
          request({
            url: "/afis-carservice/web/claim/cannel",
            method: "post",
            data: { id: id }
          })
            .then(() => {
              this.$toast("撤销成功");
              this.onSearch();
              done();
            })
            .catch(() => {
              done(false);
            });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/mixins/hairline.scss";
.row {
  padding: 10px 0;
  &-head {
    font-size: $font-size-lg;
    padding: 6px 20px;
    position: relative;
    &::after {
      @include hairline-bottom($border-color, $padding-xs, $padding-xs, $border-width-xs);
    }
  }
  &-content {
    padding: 6px 20px;
    background-repeat: no-repeat;
    background-position: 90% center;
    background-size: 70px;
    div {
      padding: 2px 0;
    }
    &_title {
      color: #8c8c8c;
      white-space: nowrap;
      text-align: right;
    }
  }
  &-foot {
    border-top: 2px solid $border-color;
    padding: 6px 20px;
    .van-button {
      margin-right: 5px;
    }
  }
}
.none-tip {
  margin: 10px;
  border-radius: 5px;
  background-color: $white;
  ::v-deep .van-image {
    margin-top: 20px;
  }
}
::v-deep .van-pull-refresh {
  min-height: calc(100vh - 98px - 50px);
}
</style>
