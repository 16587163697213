<template>
  <div class="h-backgroud-color_body">
    <van-sticky>
      <van-search v-model="name" background="#fff" placeholder="请输入姓名/车架号/车牌号" @search="onSearch"> </van-search>
    </van-sticky>
    <van-tabs v-model="active" sticky offset-top="54px">
      <van-tab title="全部"><ClaimList ref="claimListRef0" :name="name"/></van-tab>
      <van-tab title="暂存"><ClaimList ref="claimListRef1" type="8" :name="name"/></van-tab>
      <van-tab title="审核中"><ClaimList ref="claimListRef2" type="1" :name="name"/></van-tab>
      <van-tab title="审核通过"><ClaimList ref="claimListRef3" type="2" :name="name"/></van-tab>
      <van-tab title="退回修改"><ClaimList ref="claimListRef4" type="3" :name="name"/></van-tab>
      <van-tab title="拒赔"><ClaimList ref="claimListRef5" type="4" :name="name"/></van-tab>
      <van-tab title="已撤销"><ClaimList ref="claimListRef6" type="5" :name="name"/></van-tab>
    </van-tabs>
    <div class="submitBtn">
      <div class="fixed">
        <van-button block type="primary" to="/product/claim/applyList">申请</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import ClaimList from "../components/ClaimList";
import dayjs from "dayjs";
export default {
  name: "ProductClaimList",
  components: { ClaimList },
  provide() {
    return {
      pageContext: this
    };
  },
  data() {
    return {
      active: 0,
      name: null
    };
  },
  watch: {
    active: function() {
      this.onSearch();
    }
  },
  methods: {
    dayjs,
    onSearch() {
      if (!this.$refs[`claimListRef${this.active}`]) {
        return false;
      }
      this.$refs[`claimListRef${this.active}`].onSearch();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.submitBtn {
  height: 50px;
  .fixed {
    border-top: 1px solid $border-color;
    position: fixed;
    background: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
